
import './../App.css';
import { NavLink } from "react-router-dom";
function Nav() {
    return (
     <div className='Nav'>
        <NavLink to='/' className={({ isActive, isPending }) =>
                      isActive
                        ? "link active"
                        : isPending
                        ? "link pending"
                        : "link"
                    } >Home</NavLink>
        <NavLink to='impressum' className={({ isActive, isPending }) =>
                      isActive
                        ? "link active"
                        : isPending
                        ? "link pending"
                        : "link"
                    }>Impressum</NavLink>        
    </div>
    );
}

export default Nav;