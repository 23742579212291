
import './App.css';

import { StrictMode } from 'react'
import {   createBrowserRouter,  
  RouterProvider, } from "react-router-dom";
import Root from './pages/Root';
import Home from './pages/Home';
import Impressum from './pages/Impressum';


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Root />,
      children: [
        {
          index: true,          
          element: <Home/>,
        },
        {          
          path: "/home",
          element: <Home/>,
        },
        {
          path: "/impressum",
          element: <Impressum/>,
        },
      ],
    },
  ]);

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>   
  );
}

export default App;
