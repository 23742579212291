
import Nav from '../pages/Nav'
import { Outlet } from "react-router-dom";

import './../App.css';

function Root() {
    return (
        <div className='App' >
            <Nav/>
            <Outlet />
        </div>
    );
}

export default Root;