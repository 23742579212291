
import './../App.css';

function Home() {
    return (
     <div className='Home'>
        <div className='title1'>natürlich blumen</div>
        <div className='title2'>franziska klemm</div>
        <p>
            Bestellungen: <a href='email:info@franziska-klemm.de' >info@franziska-klemm.de</a>
        </p>
    </div>
    );
}

export default Home;